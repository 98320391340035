<template>
  <div class="brand w-full pb-10">
    <div
      v-if="!banner.loading"
      class="collection-banner-container"
      :style="{
        'background-color': banner.data.background_color || 'rgb(121, 95, 70)',
      }"
    >
      <Banner
        :title="banner.data.name"
        :logo="banner.data.image ? banner.data.image : '/images/no-image.png'"
        :toggle="true"
        @enabled="onStockChanged"
      >
        <template v-if="$route.path.includes('clearance')" #logo>
          <svg
            class="w-20 h-20"
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            fill="none"
            viewBox="0 0 20 20"
          >
            <path
              fill="#795f46"
              fill-rule="evenodd"
              d="M5 10H3.333a6.667 6.667 0 102.275-5.016L5.07 4.61a.5.5 0 00-.786.423l.074 2.687a.5.5 0 00.656.46l2.558-.843a.5.5 0 00.13-.884l-.676-.473A5 5 0 115 10z"
              clip-rule="evenodd"
            />
          </svg>
        </template>

        <template v-else-if="$route.path.includes('new-arrivals')" #logo>
          <svg
            class="w-20 h-20"
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            fill="none"
            viewBox="0 0 20 20"
          >
            <rect
              width="16.667"
              height="16.667"
              x="1.667"
              y="1.667"
              fill="#795f46"
              opacity=".3"
              rx="8.333"
            />
            <path
              fill="#795f46"
              fill-rule="evenodd"
              d="M5.14 12.129a.833.833 0 111.387-.925c.964 1.447 2.102 2.13 3.473 2.13s2.51-.683 3.473-2.13a.833.833 0 011.387.925C13.602 14.016 11.962 15 10 15c-1.962 0-3.602-.984-4.86-2.871z"
              clip-rule="evenodd"
            />
          </svg>
        </template>

        <template #header>
          <ol role="list" class="breadcrumb">
            <li>
              <div class="breadcrumb-item">
                <router-link to="/">Home</router-link>
              </div>
            </li>
            <li>
              <div class="breadcrumb-item">
                <span>/&nbsp;</span><a href="#">{{ banner.data.name }}</a>
              </div>
            </li>
          </ol>
        </template>
        <template #footer>
          <div class="-mb-8">
            <Sorting @onSort="onSortChanged" />
            <SortFilterMobile @onSort="onSortChanged" />
          </div>
          <!-- Footer Slot -->
        </template>
      </Banner>
    </div>
    <div class="py-10 max-container">
      <!-- Listing -->
      <div class="flex items-center space-x-4 pb-5">
        <FilterBadge />
      </div>
      <ListLoaderWrapper :isLoading="products.loading" />
      <ItemsList
        v-if="!products.loading"
        :items="products"
        :filter="[]"
        :loading="products.loading"
      />
      <ListLoaderWrapper class="mt-5" :isLoading="products.onScrollLoading" />
    </div>
  </div>
</template>

<script>
import Banner from "../common/Banner.vue";
import ItemsList from "@/views/pages/common/ItemsList.vue";
import ListLoaderWrapper from "@/components/loaders/ListLoaderWrapper.vue";
import Collections from "@/modules/Collections";
import Common from "@/modules/Common";
import { useRoute } from "vue-router";
import { mapGetters, useStore } from "vuex";
import SortFilterMobile from "@/components/SortFilterMobile.vue";
import { computed, watch } from "vue";

export default {
  name: "Home",
  components: {
    Banner,
    ItemsList,
    ListLoaderWrapper,
    SortFilterMobile,
  },
  watch: {
    filters: {
      handler: "filterCategory",
      deep: true,
    },
    priceRange: {
      handler: "priceOnChange",
      deep: true,
    },
  },
  computed: {
    ...mapGetters({ filters: "getSelected" }),
    ...mapGetters({ priceRange: "getPriceRange" }),
  },
  methods: {
    filterCategory() {
      this.onFilterChanged();
    },
    priceOnChange() {
      if(this.$store.getters.getPriceCheck) this.onPriceRangeChanged();
    },
  },
  beforeMount() {
    this.$store.dispatch("getCart", { ax: null });
  },
  setup() {
    const route = useRoute();
    const name = "collection";
    const slug = route.params.slug;
    const LoginURL = "/aCollection?collection_slug=" + route.params.slug;
    const LogoutURL = "/collection?collection_slug=" + route.params.slug;

    const store = useStore();

    const { banner, getBanner } = Collections();
    const {
      products,
      onSortChanged,
      onStockChanged,
      onFilterChanged,
      onPriceRangeChanged,
      maxPrice,
    } = Common({ name, slug }, { LoginURL, LogoutURL });
    maxPrice();
    getBanner();
    onSortChanged();

    const currentShipping = computed(() => store.getters.getSelectedAddress);

    watch(currentShipping, () => {
      if(store.getters.getAddressChange) {
        store.dispatch("setAddressChange", false);
        onSortChanged();
      }
    });

    return {
      banner,
      products,
      onSortChanged,
      onStockChanged,
      onFilterChanged,
      onPriceRangeChanged,
    };
  },
};
</script>

<style lang="scss" scoped>
.collection-banner-container {
  background-image: url("/images/lines-pattern.png");
}
</style>
